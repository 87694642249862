<template>
  <MainLayout>
    <v-container>
      <v-row dense>
        <v-col cols="12">

          <v-card class="mx-auto" max-width=400 variant=outlined color="#385F73" subtitle="Сгенерировать Корневой SSL" title="ROOT CA">
            <template v-slot:actions>
              <v-btn @click="$router.push('/root')" text="Перейти"></v-btn>
            </template>
          </v-card>
        </v-col>


        <v-col cols="12">

          <v-card class="mx-auto" max-width=400 variant=outlined color="#385F73" subtitle="Подписать SSL" title="SIGN SSL">
            <template v-slot:actions>
              <v-btn @click="$router.push('/sign')" text="Перейти"></v-btn>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </MainLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainLayout from "@/layouts/MainLayout.vue"


export default defineComponent({
  name: 'HomeView',
  components: {
    MainLayout
  },
});
</script>

<style scoped>
.card {
  width: 400px;
  height: 200px;
  background-color: rgb(0, 152, 101);
  margin: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

a,
a:visited {
  text-decoration: none;
  color: white;
}


.card__list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
</style>