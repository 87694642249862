import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormTitle = _resolveComponent("FormTitle")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        enctype: "multipart/form-data",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.generateCert && _ctx.generateCert(...args)), ["prevent"])),
        class: "input_form"
      }, [
        _createVNode(_component_FormTitle, { title: `Новый root ca` }),
        _createVNode(_component_v_text_field, {
          variant: "outlined",
          modelValue: _ctx.cert.subject.CN,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cert.subject.CN) = $event)),
          label: "commonName (CN=)",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          variant: "outlined",
          modelValue: _ctx.cert.subject.C,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cert.subject.C) = $event)),
          label: "C",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          variant: "outlined",
          modelValue: _ctx.cert.subject.ST,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cert.subject.ST) = $event)),
          label: "ST",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          variant: "outlined",
          modelValue: _ctx.cert.subject.L,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cert.subject.L) = $event)),
          label: "L",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_text_field, {
          type: "number",
          variant: "outlined",
          modelValue: _ctx.cert.age_days,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cert.age_days) = $event)),
          label: "Время жизни",
          required: ""
        }, null, 8, ["modelValue"]),
        _createVNode(_component_v_btn, {
          color: "primary",
          type: "submit"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Скачать")
          ]),
          _: 1
        })
      ], 32)
    ]),
    _: 1
  }))
}