<template>
  <MainLayout>

    <form enctype="multipart/form-data" v-on:submit.prevent="generateCert" class="input_form">
      <FormTitle :title="`Подписать SSL`" />
      <v-file-input show-size chips placeholder="Выберите корневой сертификат" label="Корневой сертификат"
        @change="ca_cert_file_Selected" required>
      </v-file-input>
      <v-file-input show-size chips placeholder="Выберите ключ корневого сертификата" label="Ключ корневого сертификата"
        @change="ca_key_file_Selected" required>
      </v-file-input>
      <v-text-field variant="outlined" v-model="cert.subject.CN" label="commonName (CN=)" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.C" label="C" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.ST" label="ST" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.L" label="L" required></v-text-field>
      <v-text-field type="number" variant="outlined" v-model="cert.age_days" label="Время жизни" required></v-text-field>

      <div class="row_input" v-for="(item, index) in cert.dns_list" :key="`dns_${index}`">
        <v-text-field variant="outlined" v-model="cert.dns_list[index]" :label="`DNS_${index}`" required></v-text-field>
        <v-btn @click="rmDNS(index)" small color="error"> - </v-btn>
      </div>
      <v-btn small color="success" @click="addDNS"> + dns </v-btn>
      <br>
      <br>
      <div class="row_input" v-for="(item, index) in cert.ip_list" :key="`ip_${index}`">
        <v-text-field variant="outlined" v-model="cert.ip_list[index]" :label="`IP_${index}`" required> </v-text-field>
        <v-btn @click="rmIP(index)" small color="error"> - </v-btn>
      </div>
      <v-btn small color="success" @click="addIP"> + ip </v-btn>
      <button> </button>
      <br>
      <br>
      <v-btn color="primary" type="submit">Подписать и скачать</v-btn>
    </form>
  </MainLayout>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import axios from 'axios'
import FormTitle from "@/components/FormTitle.vue";
import MainLayout from "@/layouts/MainLayout.vue"

interface Subject {
  CN: String;
  C: String;
  ST: String;
  L: String;
}

export interface Certificate {
  ca_cert_file: File | null | Blob;
  ca_key_file: File | null | Blob;
  age_days: number;
  dns_list: string[],
  ip_list: string[],
  subject: Subject,
}


export default defineComponent({
  name: 'CertSignView',

  data() {
    return {
      cert: {
        ca_cert_file: null,
        ca_key_file: null,
        age_days: 365,
        dns_list: ["localhost"],
        ip_list: ["127.0.0.1"],
        subject: {
          CN: "Certificate",
          C: "RU",
          ST: "Moscow oblast",
          L: "Moscow",
        },
      } as Certificate
    }
  },
  components: {
    FormTitle,
    MainLayout,
  },
  methods: {
    addIP() {
      this.cert.ip_list.push('');
    },

    addDNS() {
      this.cert.dns_list.push('');
    },
    rmIP(index: number) {
      this.cert.ip_list.splice(index, 1);
    },
    rmDNS(index: number) {
      this.cert.dns_list.splice(index, 1);
    },

    downloadFile(response: any, fileName: string) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },

    generateCert() {
      const fd = new FormData()
      if (this.cert.ca_cert_file) {
        fd.append('ca_cert_file', this.cert.ca_cert_file);
      }
      if (this.cert.ca_key_file) {
        fd.append('ca_key_file', this.cert.ca_key_file);
      }

      fd.append('age_days', String(this.cert.age_days));
      fd.append('dns_list', JSON.stringify(this.cert.dns_list));
      fd.append('ip_list', JSON.stringify(this.cert.ip_list));
      fd.append('subject', JSON.stringify(this.cert.subject));
      axios.post('/api/sign', fd, { responseType: 'blob' })
        .then((response) => {
          this.downloadFile(response, 'certs.zip')
        })
        .catch((err) => {
          console.log(err);
        });
    },


    selectFile(e: Event | null) {
      if (e) {
        const element = e.target as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
          return fileList[0];
        }
      }
    },

    ca_cert_file_Selected(event: Event | null) {
      let file = this.selectFile(event);
      console.log('file');
      console.log(file);

      if (file) {
        this.cert.ca_cert_file = file;
      }
    },
    ca_key_file_Selected(event: Event | null) {
      let file = this.selectFile(event);
      console.log('file');
      console.log(file);
      if (file) {
        this.cert.ca_key_file = file;
      }
    },
  },


})
</script>


<style scoped>
.input_form {
  max-width: 600px;
  padding: 30px;
  background-color: aliceblue;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 30px;
}

.row_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
</style>