import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { dense: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    "max-width": "400",
                    variant: "outlined",
                    color: "#385F73",
                    subtitle: "Сгенерировать Корневой SSL",
                    title: "ROOT CA"
                  }, {
                    actions: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/root'))),
                        text: "Перейти"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card, {
                    class: "mx-auto",
                    "max-width": "400",
                    variant: "outlined",
                    color: "#385F73",
                    subtitle: "Подписать SSL",
                    title: "SIGN SSL"
                  }, {
                    actions: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/sign'))),
                        text: "Перейти"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}