import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CertSignView from '../views/CertSignView.vue'
import CertRootView from '../views/CertRootView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/sign',
    name: 'CertSignView',
    component: CertSignView,
  },
  {
    path: '/root',
    name: 'CertRootView',
    component: CertRootView,
  },
]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
