<template>
      <div class="title">
        <v-btn @click="$router.go(-1)" icon="mdi-arrow-left"></v-btn> 
        <h3> {{ title }}   </h3>
      </div>  
</template>


<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: 'FormTitle',

  data() {
    return {
    }
  },
  props: {
    title: String,
  },
  methods: {},

})
</script>


<style scoped>

.title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  color: #385F73;
}

</style>