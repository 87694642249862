<template>
  <MainLayout>

    <form enctype="multipart/form-data" v-on:submit.prevent="generateCert" class="input_form">
      <FormTitle :title="`Новый root ca`" />
      <v-text-field variant="outlined" v-model="cert.subject.CN" label="commonName (CN=)" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.C" label="C" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.ST" label="ST" required></v-text-field>
      <v-text-field variant="outlined" v-model="cert.subject.L" label="L" required></v-text-field>
      <v-text-field type="number" variant="outlined" v-model="cert.age_days" label="Время жизни" required></v-text-field>
      <v-btn color="primary" type="submit">Скачать</v-btn>
    </form>

  </MainLayout>
</template>


<script lang="ts">
import { defineComponent } from "vue"
import axios from 'axios'
import FormTitle from "@/components/FormTitle.vue";
import MainLayout from "@/layouts/MainLayout.vue"

interface Subject {
  CN: String;
  C: String;
  ST: String;
  L: String;
}

export interface Certificate {
  age_days: number;
  subject: Subject,
}


export default defineComponent({
  name: 'CertRootView',

  data() {
    return {
      cert: {
        age_days: 365,
        subject: {
          CN: "Certificate",
          C: "RU",
          ST: "Moscow oblast",
          L: "Moscow",
        },
      } as Certificate
    }
  },
  components: {
    FormTitle,
    MainLayout,
  },
  methods: {

    downloadFile(response: any, fileName: string) {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },

    generateCert() {
      const fd = new FormData()

      fd.append('age_days', String(this.cert.age_days));
      fd.append('subject', JSON.stringify(this.cert.subject));
      axios.post('/api/root', fd, { responseType: 'blob' })
        .then((response) => {
          this.downloadFile(response, 'certs_root_ca.zip')
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

})
</script>


<style scoped>
.input_form {
  max-width: 600px;
  padding: 30px;
  background-color: aliceblue;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 30px;
}

.row_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
</style>