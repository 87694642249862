<template>
    <div class="main_layout">
        <h2 class="title">
            Сервис создания SSL сертификатов
        </h2>
        <slot></slot>

    </div>
</template>
  
  
<script lang="ts">
import { defineComponent } from "vue"


export default defineComponent({
    name: 'MainLayout',
})
</script>
  
  
<style scoped>
.main_layout {
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    margin-bottom: 20px;
    color: #385F73
}

@media (max-width: 1199px) {
    .main_layout {
        width: 100%;
    }

}
</style>